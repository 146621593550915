<template>
  <div>
    <b-card
        no-body
    >
      <div>
        <b-table
            :items="items"
            :fields="fields"
            striped
            responsive
            class="mb-0 orders-table"
        >
          <!-- pair -->
          <template #cell(currency_pair)="data">
            <div class="d-flex align-items-center">
              <b-avatar
                  size="32"
                  variant="light-company"
              >
                <b-img
                    :src="data.item.coin_img"
                    alt="avatar img"
                /></b-avatar>
              <div>
                <div class="font-weight-bolder">
                  {{ data.item.currency_pair }}
                </div>
              </div>
            </div>
          </template>

          <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template>
        </b-table>
        <div class="mx-2 mb-2 mt-2">
          <b-row>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                  v-model="page"
                  :total-rows="countItems"
                  per-page="20"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTable, BCard, BRow, BCol, BBadge, BPagination, BAvatar, BImg,
} from 'bootstrap-vue'
import { mapActions, mapState, mapMutations } from 'vuex'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    BBadge,
    BPagination,
    BAvatar,
    BImg,
  },
  data() {
    return {
      fields: [
        { key: 'currency_pair', label: 'Пара' },
        { key: 'position', label: 'Тип' },
        { key: 'adjust_leverage', label: 'Плечо' },
        { key: 'price', label: 'Цена' },
        { key: 'quantity', label: 'Кол-во' },
        { key: 'pnl', label: 'PNL' },
        { key: 'status', label: 'Статус' },
        { key: 'date', label: 'Время' },
      ],
      page: 1,
      status: [{
        1: 'Открыт', 2: 'Исполнен',
      },
        {
          1: 'light-primary', 2: 'light-success',
        }],
    }
  },
  computed: {
    ...mapState({
      items: state => state.userTraderOrder.orders,
      countItems: state => state.userTraderOrder.countOrders,
    }),
  },
  watch: {
    page() {
      this.getItems({
        page: this.page,
        trader_id: this.$route.params.id,
      })
    },
  },
  mounted() {
    this.getItems({
      page: this.page,
      trader_id: this.$route.params.id,
    })
  },
  methods: {
    ...mapActions({
      getItems: 'userTraderOrder/getOrders',
    }),
  },
}
</script>

<style scoped>
.log-order {
  color: #28c76f;
}

.log-order.error {
  color: #ea5455;
}

.orders-table span.b-avatar {
  margin-right: 15px;
}

.orders-table img {
  max-width: 32px;
}

.font-weight-bolder {
  white-space: nowrap;
}
</style>
