<template>
  <b-card
    no-body
    class="border-primary dark-layout"
  >
    <b-overlay :show="userData.is_subscribe" rounded="sm" variant="light">
      <template #overlay>
        Не доступно
      </template>
      <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
        <h5 class="mb-0">
          Настройки
        </h5>
      </b-card-header>

      <b-card-body>
        <div class="row-item">
          <b-card-text class="mb-0">
            Включить
          </b-card-text>
          <b-form-checkbox
              v-model="userData.is_active"
              checked="true"
              class="custom-control-success"
              name="check-button"
              switch
          />
        </div>
        <div class="row-item">
          <b-card-text class="mb-0">
            Плечо
          </b-card-text>
          <b-form-input
              v-model="userData.adjust_leverage"
              style="max-width: 50px;"
          />
        </div>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          @click="save"
        >
          Сохранить
        </b-button>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton, BCardText, BFormCheckbox, BFormInput, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BCardText,
    BFormCheckbox,
    BFormInput,
    BOverlay,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      saveSettings: 'trader/saveSettings',
    }),
    save() {
      this.saveSettings({
        is_active: this.userData.is_active,
        adjust_leverage: this.userData.adjust_leverage,
        trader_id: this.$route.params.id,
      }).then(() => {
        this.$bvToast.toast('Настройки успешно обновлены', {
          title: 'Трейдер',
          variant: 'success',
          solid: true,
        })
      })
        .catch(() => {
          this.$bvToast.toast('Ошибка', {
            title: 'Ошибка',
            variant: 'danger',
            solid: true,
          })
        })
    },
  },
}
</script>

<style scoped>
  .row-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 14px;
    margin-bottom: 18px;
  }

  .dark-layout >>> .bg-light {
    background-color: #161d31!important;
  }
</style>
