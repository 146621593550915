<template>
  <div>
    <template>
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <user-view-user-info-card :user-data="item" />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <user-view-user-plan-card :user-data="item"/>
        </b-col>
      </b-row>

<!--      <b-row>-->
<!--        <b-col-->
<!--          cols="12"-->
<!--          lg="6"-->
<!--        >-->
<!--          <user-view-user-timeline-card />-->
<!--        </b-col>-->
<!--        <b-col-->
<!--          cols="12"-->
<!--          lg="6"-->
<!--        >-->
<!--          <user-view-user-permissions-card />-->
<!--        </b-col>-->
<!--      </b-row>-->

      <orders />
    </template>

  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'
import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'
import Orders from './Orders.vue'

export default {
  components: {
    BRow,
    BCol,

    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,

    Orders,
  },
  computed: {
    ...mapState({
      item: state => state.trader.trader,
    }),
  },
  mounted() {
    this.getTrader(this.$route.params.id)
  },
  methods: {
    ...mapActions({
      getTrader: 'trader/getTrader',
    }),
  },
}
</script>

<style>

</style>
