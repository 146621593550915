<template>
  <b-card>

    <b-modal
      v-model="deleteModal"
      id="modal-danger"
      ok-only
      ok-variant="danger"
      ok-title="Отписаться"
      modal-class="modal-danger"
      centered
      title="Отписка"
      @ok="cancelSubscribe"
    >
      <b-card-text>
        Вся история сделок удалится, вы действительно хотите отписаться?
      </b-card-text>
    </b-modal>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.img"
            :text="userData.name"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.name }}
              </h4>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                v-if="userData.is_subscribe"
                variant="primary"
                @click="addTrader()"
              >
                Подписаться
              </b-button>
              <b-button
                  v-else
                  variant="outline-danger"
                  @click="deleteModal = true"
              >
                Отписаться
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                23.3k
              </h5>
              <small>Объем торгов</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="TrendingUpIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                $99.87k
              </h5>
              <small>PNL</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
<!--      <b-col-->
<!--        cols="12"-->
<!--        xl="6"-->
<!--      >-->
<!--        <table class="mt-2 mt-xl-0 w-100">-->
<!--          <tr>-->
<!--            <th class="pb-50">-->
<!--              <feather-icon-->
<!--                icon="UserIcon"-->
<!--                class="mr-75"-->
<!--              />-->
<!--              <span class="font-weight-bold">Username</span>-->
<!--            </th>-->
<!--            <td class="pb-50">-->
<!--              {{ userData.username }}-->
<!--            </td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <th class="pb-50">-->
<!--              <feather-icon-->
<!--                icon="CheckIcon"-->
<!--                class="mr-75"-->
<!--              />-->
<!--              <span class="font-weight-bold">Status</span>-->
<!--            </th>-->
<!--            <td class="pb-50 text-capitalize">-->
<!--              {{ userData.status }}-->
<!--            </td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <th class="pb-50">-->
<!--              <feather-icon-->
<!--                icon="StarIcon"-->
<!--                class="mr-75"-->
<!--              />-->
<!--              <span class="font-weight-bold">Role</span>-->
<!--            </th>-->
<!--            <td class="pb-50 text-capitalize">-->
<!--              {{ userData.role }}-->
<!--            </td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <th class="pb-50">-->
<!--              <feather-icon-->
<!--                icon="FlagIcon"-->
<!--                class="mr-75"-->
<!--              />-->
<!--              <span class="font-weight-bold">Country</span>-->
<!--            </th>-->
<!--            <td class="pb-50">-->
<!--              {{ userData.country }}-->
<!--            </td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <th>-->
<!--              <feather-icon-->
<!--                icon="PhoneIcon"-->
<!--                class="mr-75"-->
<!--              />-->
<!--              <span class="font-weight-bold">Contact</span>-->
<!--            </th>-->
<!--            <td>-->
<!--              {{ userData.contact }}-->
<!--            </td>-->
<!--          </tr>-->
<!--        </table>-->
<!--      </b-col>-->
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BCardText,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deleteModal: false,
    }
  },
  methods: {
    ...mapActions({
      getTrader: 'trader/getTrader',
      attachTrader: 'trader/attachTrader',
      unsubscribe: 'trader/unsubscribe',
    }),
    addTrader() {
      this.attachTrader(this.$route.params.id).then(response => {
        this.getTrader(this.$route.params.id)

        this.$bvToast.toast(response, {
          title: 'Трейдер',
          variant: 'success',
          solid: true,
        })
      })
        .catch(errors => {
          this.$bvToast.toast(errors.response.data, {
            title: 'Ошибка',
            variant: 'danger',
            solid: true,
          })
        })
    },
    cancelSubscribe() {
      this.unsubscribe({
        trader_id: this.$route.params.id,
      }).then(() => {
        this.getTrader(this.$route.params.id)

        this.$bvToast.toast('Вы успешно отписались', {
          title: 'Трейдер',
          variant: 'success',
          solid: true,
        })
      })
        .catch(() => {
          this.$bvToast.toast('Ошибка', {
            title: 'Ошибка',
            variant: 'danger',
            solid: true,
          })
        })
    },
  },
}
</script>

<style>

</style>
